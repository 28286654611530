import { Web } from "sip.js";

const runButton = document.getElementById("runButton");
const hangupButton = document.getElementById("hangupButton");
const resultDiv = document.getElementById("result");
const pressedDiv = document.getElementById("pressed");
const orderIdPromptBox = document.getElementById("orderIdPrompt");
const promptBox = document.getElementById("prompt");
const buttons = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "*"].map(x => document.getElementById(x.toString()));

let password = "";

const getAccount = async () => {
  while (true) {
    // try {
      password = prompt("Give me a password", "");
      const response = await fetch(`/sip`, {
        headers: {
          "Authorization": 'Basic ' + btoa("dasha" + ":" + password)
        },
      });
      if (response.status !== 200) {
        alert("Wrong response: " + response.status);
        continue;
      }
      return await response.json();
    // } catch (e) {
    //   alert(e);
    // }
  }
};

const createUser = async (aor, server) => {
  const user = new Web.SimpleUser(server, { aor });
  await user.connect();
  await user.register();
  return user;
};

const runCall = async (aor) => {
  const data = { aor, prompt: promptBox.value, order_id_prompt: orderIdPromptBox.value };
  const result = await fetch(`/call`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Basic ' + btoa("dasha" + ":" + password)
    },
    body: JSON.stringify(data),
  });
  const jresult = await result.json();
  resultDiv.textContent = JSON.stringify(jresult, undefined, 4);
};

const main = async () => {
  const { aor, endpoint, order_id_prompt, prompt } = await getAccount();
  orderIdPromptBox.value = order_id_prompt;
  promptBox.value = prompt;
  const user = await createUser(aor, endpoint);

  const audio = new Audio();
  user.delegate = {
    onCallReceived: async () => {
      await user.answer();
      runButton.hidden = true;
      hangupButton.hidden = false;
      hangupButton.disabled = false;
      audio.srcObject = user.remoteMediaStream;
      audio.play();
    },
    onCallHangup: () => {
      audio.srcObject = null;
      runButton.hidden = false;
      runButton.disabled = false;
      hangupButton.hidden = true;
    },
  };

  runButton.addEventListener("click", async () => {
    runButton.disabled = true;
    pressedDiv.textContent = "";
    runCall(aor).catch(() => {
      runButton.disabled = false;
    });
  });

  hangupButton.addEventListener("click", async () => {
    hangupButton.disabled = true;
    await user.hangup().catch(() => {
      hangupButton.disabled = false;
    });
  });
  buttons.forEach(x => x.addEventListener("click", async () => {
    await user.sendDTMF(x.id);
    pressedDiv.textContent += x.id;
  }));
}

main();
